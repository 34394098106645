<template>
  <v-container id="products" class="ma-auto pa-0" fluid>
    <v-row class="pa-0 ma-0 main-modified">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="pa-0 ma-0" :style="{'background-image': 'url(' + require('../../assets/images/products/products-bg.jpg') + ')'}" style="height:300px;filter: grayscale(0.1);background-repeat: no-repeat;background-size: cover;">
          <v-col class="text-center ma-auto">
            <h1 class="text-h4 custom-secondary--text text-uppercase" style="-webkit-text-stroke: 0.2px white;">{{
              $t("products")
            }}</h1>
          </v-col>
        </v-row>
        <transition name="fade" mode="out-in" v-if="product_loading">
          <v-row class="pa-8 ma-0 mt-8" justify="center">
            <v-col
              v-for="i in itemsperpage"
              :key="i"
              cols="12"
              sm="10"
              md="6"
              lg="4"
            >
              <v-skeleton-loader
                type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="products.length==0 && !product_loading">
          <v-row class="text-center pa-12 ma-auto my-7" ><i class="ma-auto text-h4 text--secondary">{{$t("noresult")}}.</i></v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="products.length && !product_loading">
          <v-row class="pa-8 ma-0 mt-8" justify="center" :key="itemsperpage * page">
            <v-col
              v-for="(item, i) in products.slice(
                itemsperpage * (page - 1),
                itemsperpage * page
              )"
              :key="i"
              cols="12"
              sm="10"
              md="6"
              lg="4"
            >
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                    class="elevation-0"
                    outlined
                    @click="ViewProduct(item.id)"
                    :class="hover ? 'custom-accent lighten-1 ' : ''"
                    :dark="hover"
                  >
                    <div
                      class="
                        text-center text-sm-left
                        d-sm-flex
                        flex-no-wrap
                      "
                    >
                      <v-avatar class="ma-3" size="180" tile>
                        <v-img v-if="item.image" :src="require('../../assets/'+item.image)" :alt="item.alt"></v-img>
                        <v-img v-else :src="require('../../assets/images/products/products-default-bg.png')" :alt="$t('productsdefaultbgalt')"></v-img>
                      </v-avatar>
                      <div>
                        <v-card-title class="mb-5"
                          ><span v-line-clamp="1">{{
                            $i18n.locale == "en" ? item.titleEN : item.titleFR
                          }}</span></v-card-title
                        >
                        <v-card-subtitle><span class="v-html-line-clamp-4" v-html="$i18n.locale == 'en'? item.descriptionEN: item.descriptionFR"></span></v-card-subtitle
                        >
                      </div>
                      <transition name="fade-right" mode="in-out">
                        <v-avatar v-if="hover" class="ml-auto my-auto" size="80" tile>
                          <v-icon class="text-h4">mdi-arrow-right</v-icon>
                        </v-avatar>
                      </transition>
                    </div>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
          </v-row>
        </transition>
        <v-row class="pa-0 ma-0">
          <v-col v-if="Math.ceil(products.length / itemsperpage)>1">
            <v-pagination
              v-model="page"
              :length="Math.ceil(products.length / itemsperpage)"
              color="custom-accent"
              :total-visible="7"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
        <v-dialog v-model="productdetailsoverlay" width="1900px" scrollable>
          <v-card class="overflow-hidden">
            <v-card-title class="pa-0 ma-0 pa-4 mr-6">
              <span class="text-h5 custom-accent--text">{{$i18n.locale == "en" ? activeproduct.subtitleEN : activeproduct.subtitleFR}}</span>
              <v-tooltip left color="custom-secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    absolute
                    top
                    right
                    style="z-index: 3;"
                    v-bind="attrs"
                    v-on="on"
                    :title="$t('close')"
                    @click="productdetailsoverlay = false"
                    icon
                  >
                    <v-icon large>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("close") }}</span>
              </v-tooltip>
            </v-card-title>
            <v-divider class="my-4"></v-divider>
            <v-card-text>
              <v-row class="pa-0 ma-0">
                <v-col cols="12" sm="auto">
                  <v-img v-if="activeproduct.image" class="ma-auto" width="130" :src="require('../../assets/'+activeproduct.image)" :alt="activeproduct.alt"></v-img>
                  <v-img v-else class="ma-auto" width="130" :src="require('../../assets/images/products/products-default-bg.png')" :alt="$t('productsdefaultbgalt')"></v-img>
                </v-col>
                <v-col cols="12" sm="">
                  <h1 class="title">{{$i18n.locale=='en'?activeproduct.titleEN:activeproduct.titleFR}}</h1>
                  <span class="caption" v-html="$i18n.locale=='en'?activeproduct.descriptionEN:activeproduct.descriptionFR"></span>
                </v-col>
              </v-row>
              <v-row class="pa-0 ma-0">
                <v-col
                  v-for="attachment in product_attachments.filter(item=>{return item.product==activeproduct.id})"
                  :key="attachment.id"
                  class="pa-3 ma-0"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-input
                    class="custom-light-gray ma-0 pa-3"
                    :messages="bytesToSize(attachment.size)"
                    :prepend-icon="attachment.icon"
                  >
                    <v-list-item-subtitle>{{attachment.name}}</v-list-item-subtitle>
                    <v-icon class="ml-auto clickable" @click="downloadAttachment(attachment)">mdi-download</v-icon>
                  </v-input>
                </v-col>
              </v-row>
              <v-divider class="my-4"></v-divider>
              <v-row class="pa-0 ma-auto" justify="center">
                <v-col
                  class="ma-auto"
                  cols="12"
                  v-for="service in product_services.filter(item=>{return item.product==activeproduct.id})"
                  :key="service.id"
                  sm="6"
                  md="4"
                >
                  <v-card height="100%" class="values-cards text-center transparent elevation-0">
                    <v-card-title>
                      <v-avatar
                        class="ma-auto"
                        style="border: 1px solid !important"
                        size="88"
                      >
                        <v-icon x-large>
                          {{ service.icon }}
                        </v-icon>
                      </v-avatar>
                    </v-card-title>
                    <v-card-title>
                      <expandable-text-line class="overflow-hidden">
                        <span class="ma-auto">{{
                          $i18n.locale == "en" ? service.titleEN : service.titleFR
                        }}</span>
                      </expandable-text-line>
                    </v-card-title>
                    <v-card-text class="font-weight-light custom-secondary--text">
                      <expandable-text-line class="overflow-hidden">
                        <span v-html="$i18n.locale == 'en'? service.descriptionEN: service.descriptionFR"></span>
                      </expandable-text-line>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ExpandableTextLine from 'vue-expandable-text-line';
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "Products",

  data() {
    return {
      page: 1,
      itemsperpage: 3,
      productdetailsoverlay: false,
      activeproduct: {},
    };
  },
  components: {'expandable-text-line':ExpandableTextLine},
  async created() {
    await this.retrieveProducts().then(()=>{
      this.products.reverse();
    });
    await this.retrieveProductServices();
    await this.retrieveProductAttachments();
    if(this.$route.params.productid){
      this.ViewProduct(this.$route.params.productid);
    }
  },
  computed: {
    ...mapGetters('product', {
      products:'getProducts'
    }),
    ...mapState("product", {
      product_loading: "loading"
    }),
    ...mapGetters('product_service', {
      product_services:'getProductServices'
    }),
    ...mapState("product_service", {
      product_service_loading: "loading"
    }),
    ...mapGetters('product_attachment', {
      product_attachments:'getProductAttachments'
    }),
    ...mapState("product_attachment", {
      product_attachment_loading: "loading"
    }),
  },
  watch: {
    $route() {
      if(this.$route.params.productid){
        this.ViewProduct(this.$route.params.productid);
      }
    },
    page(){
      this.$root.$emit('toTop');
    },
  },
  methods: {
    ViewProduct(id) {
      let product = this.products.filter(product=>{
        return product.id==id;
      })[0];
      this.productdetailsoverlay = true;
      this.activeproduct = product;
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    downloadAttachment(attachment){
      this.downloadAttachment(attachment);
    },

    ...mapActions("product", ["retrieveProducts"]),
    ...mapActions("product_service", ["retrieveProductServices"]),
    ...mapActions("product_attachment", ["retrieveProductAttachments","downloadAttachment"]),
  },
};
</script>
<style scoped>
@import url("../../assets/styles/products.css");
</style>